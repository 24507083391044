import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom/dist';
import {Link as ScrollLink, animateScroll as Scroll } from 'react-scroll';


const CategoryWidget = ({children, className})=> (  
  <ul className={`widget-cat-list ${className || ''}`}>{children}</ul>
);

const Item = ({children, path})=> {
  let location  = useLocation();
  return (
  <li>
    { getLink(location, path, children) }
    {/* <Link to={path}>{children}</Link> */ }
  </li>
)};

CategoryWidget.Item = Item;

function getLink(location, path, children){
  let pathWithoutHashwindow = path.substr(0, window.location.href.indexOf('#'))
  const inSamePage = !location.pathname?.startsWith(pathWithoutHashwindow);
  console.log('path => ', pathWithoutHashwindow, ' is the same: ', inSamePage);
  if(inSamePage && path && (path.startsWith('#') || path.startsWith('/#'))){
    let to = path.replace('/#', '').replace('#','');
    return (
      <ScrollLink
          href={path || '#'}  
          to={to}
          activeClass="active"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >{children}</ScrollLink>
    )}
  return (<Link to={path || "#"} >{children}</Link>)
}


export default CategoryWidget;