import React from 'react';
import {Link as ScrollLink, animateScroll as Scroll} from 'react-scroll';
import { Link, useLocation } from 'react-router-dom/dist';
import { getClosest, getSiblings, slideToggle, slideUp } from '../../utils/utils';


const MenuItem = ({item, toggle, onClick})=> {

  const location = useLocation();

  // Submenu Toggle Handler
  const menuExpandHandler = (e) => {
    const target = e.currentTarget;
    const parentEl = target.parentElement;
    if (
      parentEl?.classList.contains("vs-mean-expand") ||
      target.classList.contains("vs-mean-expand")
    ) {
      const element = target.classList.contains("icon") ? parentEl : target;
      const parent = getClosest(element, "li");
      const childNodes = parent.childNodes;
      const parentSiblings = getSiblings(parent);
      parentSiblings.forEach((sibling) => {
        if (sibling.classList.contains("active")) {
          sibling.classList.remove("active");
        }
        const sibChildNodes = sibling.childNodes;
        sibChildNodes.forEach((child) => {
          if (child.nodeName === "UL") {
            slideUp(child, 400);
          }
        });
      });
      childNodes.forEach((child) => {
        if (child.nodeName === "UL") {
          slideToggle(child, 400);
        }
      });
      parent.classList.toggle("active");
    }
  };

  if (item.subMenu) {
    return (
      <li className="menu-item-has-children">
        {getLink(item, onClick, location)}
        {toggle && <span className="vs-mean-expand" onClick={menuExpandHandler}></span>}
        <ul className="sub-menu">
          {item.subMenu.map((child, index) => (
            <MenuItem location key={index} item={child} />
          ))}
        </ul>
      </li>
    );
  } else {
    return (
      <li>
        {getLink(item, onClick, location)}
      </li>
    );
  }
}

function getLink(item, onClick, location){
  // if(!item.path || item.path == '/'){
  //   return (<Link to='/' onClick={() => Scroll.scrollToTop()}>{item.name}</Link>)
  // }
  let pathWithoutHashwindow = (item.path || '').substr(0, window.location.href.indexOf('#'))
  const inSamePage = !location.pathname?.startsWith(pathWithoutHashwindow);
  if(inSamePage && item.path && (item.path.startsWith('#') || item.path.startsWith('/#'))){
    let to = item.path.replace('/#', '').replace('#','');
    console.log('Scroll link for [' + item.name + '] to [' + (to || '#') + ']');
    return (
      <ScrollLink
          onClick={onClick}
          href={item.path || '#'}  
          target={item.target}
          to={to}
          activeClass="active"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >{item.name}</ScrollLink>
    )}
    console.log('Normal link for [' + item.name + '] to [' + (item.path || '#') + ']');
  return (<Link target={item.target} to={item.path || "#"} onClick={() => {
    if(onClick)
    {
      onClick();
    }
    if(!item.path || item.path == '/' || item.path == '#'){
      Scroll.scrollToTop();
    }
  }}>{item.name}</Link>)
}

export default MenuItem;