import React from 'react';
import {InfoMedia} from '../../components';

const CounterThree = ({className})=> (
  <div className={`counter-wrap1 ${className || ''}`}>    
    <div className="container">
      <div className="row justify-content-between gy-4">
        <div className="col-6 col-lg-auto">
          <InfoMedia className="counter-media style2"
            image="images/icon/count-1-1.png"
            title="20+"
            info="Successful Projects"
          />
        </div>
        <div className="col-6 col-lg-auto">
          <InfoMedia className="counter-media style2"
            image="images/icon/count-1-2.png"
            title="10+"
            info="Years of experience"
          />
        </div>
        <div className="col-6 col-lg-auto">
          <InfoMedia className="counter-media style2"
            image="images/icon/count-1-3.png"
            title="6+"
            info="Countries Served"
          />
        </div>
        <div className="col-6 col-lg-auto">
          <InfoMedia className="counter-media style2"
            image="images/icon/count-1-4.png"
            title="50+"
            info="Happy Clients"
          />
        </div>
      </div>
    </div>
  </div>
);


export default CounterThree;