import React, { Fragment, useEffect } from 'react';
import {Seo, ScrollTopBtn} from '../components';
import {
  HeaderFour,
  HeroThree,
  ServiceThree,
  CounterThree,
  AboutFive,
  CtaOne,
  AboutSix,
  TeamThree,
  ProjectTwo,
  ProcessTwo,
  TestimonialTwo,
  BrandOne,
  BlogFive,
  FooterThree
} from '../containers';

import { useLocation } from 'react-router-dom';

const HomeThree = ()=>{

const location = useLocation();
useEffect(()=> {
 function scrollToLocation() {
    let top = 0;
    if (location?.hash) {
      let elem = document.getElementById(location?.hash.replace('#', ''))
      if (elem) {
        top = elem.offsetTop - 70;
      }
    }
    console.log('scrolling to ', top, 'for ', location?.hash);
    window.scrollTo({top: top, behavior: "smooth"});
  }
  scrollToLocation();
  return scrollToLocation;
}, [location])

  return (
    <Fragment>
      <Seo title="IKEN Technology" />
      <HeaderFour/>
      <HeroThree/>
      <ServiceThree className="space-top space-extra-bottom"/>
      <CounterThree className="space"/>
      <AboutFive className="space-top space-extra-bottom" id='about'/>
      <CtaOne className="space"/>
      {/* <AboutSix className="space"/> */}
      {/* <TeamThree className="space-top space-extra-bottom"/> */}
      <ProjectTwo className="space"/>
      <ProcessTwo className="space-top space-extra-bottom"/>
      {/* <TestimonialTwo className="space-top space-extra-bottom"/> */}
      <BrandOne id='clients' className="space background-image" style={{backgroundImage: 'url(images/bg/brand-bg-2-2.jpg)'}}/>
      {/* <BlogFive className="space-top space-extra-bottom"/> */}
      <FooterThree/>
      <ScrollTopBtn/>
    </Fragment>
  );
 }

export default HomeThree;