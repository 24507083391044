import React from 'react';
import Slider from 'react-slick';
import {
  TitleWrap,
  SecSubTitle,
  SecTitle,
  ProjectBoxTwo
} from '../../components';

const ProjectTwo = ({className})=> {
  
  const settings = {
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <div className={`background-image ${className || ''}`} style={{ backgroundImage: 'url(images/bg/project-bg-1-1.jpg)'}} id="products" >
      <div className="container">
        <TitleWrap className="text-center">
          <SecSubTitle>Some Top Projects</SecSubTitle>
          <SecTitle className="h1 text-capitalize">Our Recent Projects</SecTitle>
        </TitleWrap>
        <Slider className="row" {...settings}>
          <ProjectBoxTwo path="/projects/contact-cars"
            title="Contact Cars"
            image="images/project/p-contactcars.jpg"
          />
          <ProjectBoxTwo path="/project-details/furn"
            title="Furn"
            image="images/project/p-furn.jpg"
          />
          {/* <ProjectBoxTwo path="/project-details/collect"
            title="Collect"
            image="images/project/p-collect.jpg"
          /> */}
          {/* <ProjectBoxTwo path="/project-details/miles-delivery"
            title="Miles Delivery"
            image="images/project/p-miles-delivery.jpg"
          /> */}
          {/* <ProjectBoxTwo path="/project-details/balad"
            title="Balad"
            image="images/project/p-balad.jpg"
          /> */}
          {/* <ProjectBoxTwo path="/project-details/ems"
            title="EMS"
            image="images/project/p-ems.jpg"
          /> */}
          <ProjectBoxTwo path="/project-details/home-care"
            title="Home Care"
            image="images/project/p-homecare.jpg"
          />
          {/* <ProjectBoxTwo path="/project-details/simple-broker"
            title="Simple Broker"
            image="images/project/p-simplebroker.jpg"
          /> */}
          <ProjectBoxTwo path="/project-details/moqwalat"
            title="Moqawalat"
            image="images/project/p-moqawalat.jpg"
          />
          {/* <ProjectBoxTwo path="/project-details/beadealer"
            title="Be a dealer"
            image="images/project/p-beadealer.jpg"
          /> */}
        </Slider>        
        <div className="text-center">
          <h4 className="fs-md fw-semibold mb-n1 mt-4">We Have Done More Than 20 Projects in Last 4 Years, With 100% Satisfaction.</h4>
        </div>
      </div>
    </div>
  );
}

export default ProjectTwo;