import React, { useState } from "react";
import axios from "axios";
import { Button } from "../";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FormFive = () => {
  const options = [
    'Web Development',
    'UI Design',
    'CMS Development',
    'Theme Development',
    'Wordpress Development'
  ];
  const emptyFormData = {
    name: "",
    number: "",
    subject: "nothing",
    message: "",
  };

  const [formData, setFormData] = useState(emptyFormData);

  const resetFormData = () => {
    setFormData(emptyFormData);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const formHandle = async (event) => {
    event.preventDefault(); // Prevent form submission
    if (!formData.name || !/^[a-zA-Z\s]+$/.test(formData.name)) {
      toast.error("Please enter a valid name.", { autoClose: 3000 });
      return;
    }

    if (!formData.number || !/^\d{11}$/.test(formData.number)) {
      toast.error("Please enter a valid phone number.", { autoClose: 3000 });
      return;
    }
    if (!formData.subject || !formData.message) {
      toast.error("Please fill in all required fields.", { autoClose: 3000 });
      return;
    }
    const url = process.env.REACT_APP_API_URL;
    axios
      .post(url, formData)
      .then((res) => {
        let data = res.data;
        if (data.success) {
          toast.success(data.message, { autoClose: 3000 });
          resetFormData();
        } else {
          toast.error(data.message, { autoClose: 3000 });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <div className="vs-contact-form">
      <ToastContainer />
      <div className="row gx-20">
        <div className="col-md-6 form-group">
          <input
            type="text"
            placeholder="Your Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
          <i className="fal fa-user" />
        </div>
        <div className="col-md-6 form-group">
          <input
            type="number"
            placeholder="Your Phone Number"
            name="number"
            value={formData.number}
            onChange={handleChange}
          />
        </div>
        <div className="col-12 form-group">

          <select
            name="subject"
            value={formData.subject}
            onChange={handleChange}
          >
            <option value="nothing">Select subject</option>
            {options.map((option) => (
              <option value={option} key={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 form-group">
          <textarea
            placeholder="Type Your Message"
            name="message"
            value={formData.message}
            onChange={handleChange}
          />
        </div>
        <div className="col-12">
          <Button onclick={formHandle}>
            Submit Message
            <i className="far fa-arrow-right" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FormFive;
